<template lang="pug">
v-row
  v-col(cols="12")
    v-card
      v-card-title Добавление терминала
      
</template>

<script>
export default {
  name: 'CreateTerminal',
};
</script>
